import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box, LinearProgress } from "@mui/material";
import { useFeatureFlagEnabled } from "posthog-js/react";

import { useConsumption } from "../ConsumptionOverview/Consumption.store";
import { FetchRangeSelector } from "../ConsumptionOverview/FetchRangeSelector";
import { FilterBar } from "../ConsumptionOverview/FilterBar";
import { MeterData } from "../ConsumptionOverview/MeterChartLines";
import { SignatureDiagram } from "../ConsumptionOverview/SignatureDiagram";
import FaultDetectionSummary from "./FaultDetectionSummary";

export const FaultDetectionOverviewTab = observer(() => {
  const { t } = useTranslation(["extendView", "_messages"]);
  const consumption = useConsumption();
  const isFaultDetectionFlagsEnabled = useFeatureFlagEnabled("fault_detection_flags");

  return (
    <Box p={4} bgcolor="whitesmoke">
      <Box>
        <FetchRangeSelector title={t("text_fault_summary", { ns: "extendView" })} />
      </Box>

      {/* Fault KPI and Notes */}
      <FaultDetectionSummary />

      <Box mt={2} mb={17}>
        {!consumption.hasPartialData && <LinearProgress />}

        <MeterData isFaultDetection={!!isFaultDetectionFlagsEnabled} />

        <SignatureDiagram disableReferencePeriod />
      </Box>

      {/* Filter Bar | Interval, Speed Options and Date Ranges */}
      <FilterBar disableReferencePeriod />
    </Box>
  );
});
